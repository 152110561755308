import TextField from "@mui/material/TextField";
import { Grid, Button } from "@mui/material";
import { useState } from "react";
import hostNameServer from "../configHost";
import { withSnackbar } from "../components/UI/withSnackbar";
import { useAuthContext } from "../Hooks/useAuthContext";

const Login = (props) => {
  const { dispatch } = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await fetch(`${hostNameServer}/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: username, password: password }),
      });

      const responseData = await response.json();

      if (response.ok) {
        props.snackbarShowMessage("Je bent ingelogd");

        localStorage.setItem("user", JSON.stringify(responseData));

        dispatch({ type: "LOGIN", payload: responseData });
      } else {
        props.snackbarShowMessage(responseData.error);
      }
    } catch (error) {
      props.snackbarShowMessage("Oeps er ging iets mis");
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        padding={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <h2 className="pb-2">EV Charger</h2>
          <form onSubmit={handleSubmit}>
            {" "}
            {/* Voeg een form-tag toe en stel onSubmit in */}
            <TextField
              required
              id="filled-required-username"
              sx={{ mb: 2 }}
              mode="dark"
              label="Gebruikersnaam"
              variant="filled"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username"
              onKeyDown={handleKeyPress}
            />
            <TextField
              required
              id="filled-required-password"
              mode="dark"
              sx={{ mb: 2 }}
              label="Wachtwoord"
              variant="filled"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              autoComplete="current-password"
              onKeyDown={handleKeyPress}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
            >
              Inloggen
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default withSnackbar(Login);
