import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid } from "@mui/material";

const LogItem = ({ log }) => {
  function transformDate(originalDate) {
    return new Date(originalDate).toLocaleString("nl-NL", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
    });
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ marginBottom: "15px" }}
    >
      <Grid item xs={1}>
        {log.type === "success" ? (
          <CheckCircleIcon style={{ color: "#25c781" }} />
        ) : (
          <ErrorIcon style={{ color: "#dd3333" }} />
        )}
      </Grid>
      <Grid item item xs={10}>
        <p style={{ margin: 0 }}>{log.message}</p>
        <p style={{ margin: 0, color: "grey", fontSize: "13px" }}>
          {log.mode === "general" ? "Algemeen" : log.mode} -{" "}
          {transformDate(log.date)}
        </p>
      </Grid>
    </Grid>
  );
};

export default LogItem;
