import { React } from "react";
import CarSetting from "../components/UI/CarSetting";
import Switch from "@mui/material/Switch";
import HeaderBox from "../components/UI/HeaderBox";

const Tesla = () => {
  return (
    <div className="container">
      <div className="row">
        <HeaderBox page="/instellingen" />
        <CarSetting title="Inschakelen" type="checkbox" />
        <CarSetting title="Tijdstip gereed" type="number" />
        <CarSetting title="Gewenst aantal km" type="number" />
      </div>
    </div>
  );
};

export default Tesla;
