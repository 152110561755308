import { createContext, useReducer, useEffect } from "react";
import hostNameServer from "../configHost";
export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "UPDATE_SETTINGS":
      const { settingKey, value } = action.payload;

      const updatedSettings = { ...state.settings };

      if (settingKey === "teslaTurnedOn" && value === true) {
        updatedSettings.hyundaiTurnedOn = false;
        updatedSettings.manualTurnedOn = false;
      }
      if (settingKey === "hyundaiTurnedOn" && value === true) {
        updatedSettings.teslaTurnedOn = false;
        updatedSettings.manualTurnedOn = false;
      }
      if (settingKey === "manualTurnedOn" && value === true) {
        updatedSettings.hyundaiTurnedOn = false;
        updatedSettings.teslaTurnedOn = false;
      }

      updatedSettings[settingKey] = value;

      async function updateSettingsInDb() {
        const response = await fetch(`${hostNameServer}/api/settings/update`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedSettings),
        });
      }

      updateSettingsInDb();

      return {
        ...state,
        settings: updatedSettings,
        updateSettings: true,
      };
    case "FETCH_SETTINGS":
      return { ...state, settings: action.payload, updateSettings: false };
    case "FETCH_MESSAGE_SETTINGS":
      console.log(action.payload);
      return {
        ...state,
        settings: {
          ...state.settings,
          chargerStatus: action.payload,
        },
        updateSettings: false,
      };
    case "FETCH_CHARGER_STATE":
      return { ...state, chargerState: action.payload };
    default:
      return state;
  }
};

const fetchStatusFromDb = async () => {
  try {
    const response = await fetch(`${hostNameServer}/api/settings/get`);

    const jsonData = await response.json();

    if (response.ok) {
      console.log(jsonData);
      console.log("RESPONSE");
    } else {
      console.error("Fout bij het ophalen van gegevens:", response.statusText);
    }
  } catch (error) {
    console.error("Fout bij het ophalen van gegevens:", error);
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    settings: null,
    updateSettings: true,
    chargerState: null,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      dispatch({ type: "LOGIN", payload: user });
    }
  }, []);

  useEffect(() => {
    async function getSettings() {
      try {
        const response = await fetch(`${hostNameServer}/api/settings/get`);

        const jsonData = await response.json();

        if (response.ok) {
          dispatch({
            type: "FETCH_MESSAGE_SETTINGS",
            payload: jsonData.chargerStatus,
          });
          console.log("RESPONSE");
        } else {
          console.error(
            "Fout bij het ophalen van gegevens:",
            response.statusText,
          );
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);
      }
    }

    if (state.updateSettings === true) {
      getSettings();
    }
  }, [state.settings]);

  console.log("AuthContext state", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
