import hostNameServer from "../configHost";
import { useAuthContext } from "./useAuthContext";

const useGetAppSettings = () => {
  const { dispatch } = useAuthContext();

  const getAppSettings = async () => {
    try {
      const response = await fetch(`${hostNameServer}/api/settings/get`);

      const jsonData = await response.json();

      if (response.ok) {
        dispatch({ type: "FETCH_SETTINGS", payload: jsonData });
      } else {
        console.error(
          "Fout bij het ophalen van gegevens:",
          response.statusText,
        );
      }
    } catch (error) {
      console.error("Fout bij het ophalen van gegevens:", error);
    }
  };

  const updateAppSettings = async (updateData) => {
    try {
      const response = await fetch(`${hostNameServer}/api/settings/update`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateData),
      });

      const jsonData = await response.json();
      console.log(jsonData);

      if (!response.ok) {
        console.error(
          "Fout bij het bijwerken van gegevens:",
          response.statusText,
        );
      }

      if (response.ok) {
        // dispatch({ type: "FETCH_SETTINGS", payload: jsonData });
      }
    } catch (error) {
      console.error("Fout bij het bijwerken van gegevens:", error);
    }
  };

  return { getAppSettings, updateAppSettings };
};

export default useGetAppSettings;
