import { React, useEffect, useState } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLogout } from "../Hooks/useLogout";
import { withSnackbar } from "../components/UI/withSnackbar";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SettingsIcon from "@mui/icons-material/Settings";
import useChargerStatus from "../Hooks/useChargerStatus";
import NavItem from "./UI/NavItem";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useAuthContext } from "../Hooks/useAuthContext";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

function NavItems(props) {
  const { logout } = useLogout();
  const { getChargerStatus } = useChargerStatus();
  const [chargerState, setChargerState] = useState("");
  const { settings, user } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getChargerStatus();
        console.log(result);
        setChargerState(result.cloudConnectionState);
      } catch (error) {
        console.error("Error fetching charger status:", error);
      }
    };

    fetchData();
  }, []);

  function handleLogout() {
    logout();
    props.snackbarShowMessage("Je bent uitgelogd");
  }

  return (
    <div className="container">
      <NavItem
        title="Plannen"
        to="/chargeplanning"
        icon={<WatchLaterIcon className="navItemIcon" />}
      >
        {chargerState && chargerState !== "Online" ? (
          <span style={{ color: "#25c781", fontSize: "0.8rem" }}>
            Charger status: {chargerState}
          </span>
        ) : (
          <div style={{ color: "grey", fontSize: "0.8rem" }}>
            {settings?.teslaTurnedOn && (
              <span>Tesla auto charging is geactiveerd</span>
            )}
            {settings?.hyundaiTurnedOn && (
              <span>Hyundai auto charging is geactiveerd</span>
            )}
          </div>
        )}
      </NavItem>
      <NavItem
        to="/instellingen"
        title="Charge modus"
        icon={<SettingsIcon className="navItemIcon" />}
      />
      <NavItem
        to="/statistieken"
        title="Charger informatie"
        icon={<BarChartIcon className="navItemIcon" />}
      />
      {user.user === "Rik" && (
        <NavItem
          to="/logging"
          title="Logging"
          icon={<SpeakerNotesIcon className="navItemIcon" />}
        />
      )}
      <NavItem
        onClick={handleLogout}
        title="Uitloggen"
        icon={<ExitToAppIcon className="navItemIcon" />}
      />
    </div>
  );
}

export default withSnackbar(NavItems);
