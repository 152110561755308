import React, { useState } from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { Slide } from "@mui/material";

export const withSnackbar = (WrappedComponent) => {
  return (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("I'm a custom snackbar");
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] =
      useState("success"); /** error | warning | info */

    const showMessage = (message, severity = "success", duration = 2000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    const getAlertStyle = () => {
      return severity === "success"
        ? { backgroundColor: "#25c781", color: "white" }
        : {};
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          fullWidth
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
          TransitionComponent={Slide}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={severity}
            style={getAlertStyle()}
          >
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};
