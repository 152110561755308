import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import EvStationIcon from "@mui/icons-material/EvStation";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

const CarSetting = ({ title, type, settingKey, dropdownItems }) => {
  const { settings, dispatch } = useAuthContext();
  // console.log(dropdownItems);

  const handleSwitchChange = (event) => {
    dispatch({
      type: "UPDATE_SETTINGS",
      payload: { settingKey, value: event.target.checked },
    });
  };

  const handleTextFieldChange = (event) => {
    dispatch({
      type: "UPDATE_SETTINGS",
      payload: { settingKey, value: event.target.value },
    });
  };

  const handleChange = (event) => {
    dispatch({
      type: "UPDATE_SETTINGS",
      payload: { settingKey, value: event.target.value },
    });
  };

  return (
    <div
      style={{ height: "75px", margin: "12px" }}
      className="d-flex align-items-center"
    >
      {type === "checkbox" && (
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex">
            {title === "Handmatig" ? (
              <ManageHistoryIcon className="me-3" />
            ) : (
              <EvStationIcon className="me-3" />
            )}

            <h4 className="m-0">{title}</h4>
          </div>
          <div>
            <Switch
              label="toggler"
              onChange={handleSwitchChange}
              checked={settings ? settings[settingKey] : false}
            />
          </div>
        </div>
      )}
      {type === "number" && (
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          <TextField
            id="outlined-basic"
            label={title}
            variant="outlined"
            style={{ width: "100%" }}
            value={settings ? settings[settingKey] || "" : ""}
            onChange={handleTextFieldChange}
          />
        </div>
      )}
      {type === "dropdown" && (
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{title}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                settings
                  ? settings[settingKey] !== undefined
                    ? settings[settingKey]
                    : ""
                  : ""
              }
              label={title}
              onChange={handleChange}
            >
              {dropdownItems &&
                dropdownItems.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      )}
    </div>
  );
};

export default CarSetting;
