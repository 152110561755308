const hostname = window.location.hostname;
let hostNameServer;
console.log(hostname)

if (hostname === 'localhost' || hostname === '127.0.0.1') {
  hostNameServer = "http://localhost:4000";
} else if (hostname === 'smarthome.rikmaaskant.nl') {
  hostNameServer = "https://energy-monitor-backend.onrender.com";
} else {
  // Onbekende host
  console.log('Toegang geweigerd');
}

export default hostNameServer;