import { React, useState, useEffect } from "react";
import NavItems from "../components/NavItems";
import { useAuthContext } from "../Hooks/useAuthContext.js";
import { Grid, LinearProgress } from "@mui/material";
import Tesla from "../Images/Tesla.png";
import Hyundai from "../Images/hyundai.png";
import { Box } from "@mui/material";
function Dashboard() {
  const { user, settings } = useAuthContext();

  return (
    <div>
      {user && (
        <div>
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={12} sx={{ mt: 5, mb: 3 }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <h1 style={{ margin: 0 }}>
                  Welkom, <span style={{ color: "#25c781" }}>{user.user}</span>
                </h1>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#25c781" }}>
                  {settings?.battery_range} km
                </span>
              </Box>
            </Grid>
            <Grid>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box width="80%">
                  <img
                    src={user.user !== "Jan" ? Tesla : Hyundai}
                    alt="Tesla"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <NavItems />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
