import { Skeleton } from "@mui/lab";
import { Divider } from "@mui/material";

const StatisticRow = ({ text, value }) => {
  return (
    <div>
      <div className="my-3" style={{ margin: "12px" }}>
        {value || value === 0 ? (
          <div className="d-flex justify-content-between">
            <p className="m-0">{text}</p>
            <p className="m-0" style={{ color: "#25c781" }}>
              {value}
            </p>
          </div>
        ) : (
          <Skeleton animation="wave" width="100%" height={40} />
        )}
      </div>
      <Divider />
    </div>
  );
};

export default StatisticRow;
