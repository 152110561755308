import React, { useEffect, useState } from "react";
import BarChartComponent from "../components/Barchart";
import "../styles.css";
import hostNameServer from "../configHost.js";
import { useAuthContext } from "../Hooks/useAuthContext";

function ChargePlanning() {
  const [data, setData] = useState([]);
  const [displayButton, setDisplayButon] = useState(false);
  const [chargeHours, setChargeHours] = useState();
  const [chargeNotification, setChargeNotification] = useState();
  const { settings } = useAuthContext();

  //show button when bars change
  function showButton(state) {
    setDisplayButon(state);
  }

  //get desiredcharge hours
  useEffect(() => {
    async function getChargeHours() {
      try {
        const response = await fetch(`${hostNameServer}/chargehours`);
        if (response.ok) {
          const jsonData = await response.json();
          // console.log(jsonData)
          setChargeHours(jsonData);
        } else {
          console.error(
            "Fout bij het ophalen van gegevens:",
            response.statusText,
          );
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);
      }
    }
    getChargeHours();
  }, []);

  //when screen opens, new data is fatched
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        getChargeData();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  function getChargeData() {
    getData();
    //get current date with time 00:00:00
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    //get the date of tomorrow with 00:00:00
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    tomorrowDate.setHours(0, 0, 0, 0);

    //check if already stored
    const thisDate = sessionStorage.getItem(currentDate);
    const dateOfTomorrow = sessionStorage.getItem(tomorrowDate);

    if (!thisDate && !dateOfTomorrow) {
      // console.log("beide data zijn niet in de local storage, doe een request")
      getData();
    }
    if (thisDate && !dateOfTomorrow) {
      // console.log("vandaag is beschikbaar, morgen niet")
      setData(JSON.parse(thisDate));
      getData();
    }

    if (thisDate && dateOfTomorrow) {
      // console.log('Beide data beschikbaar, do nothing');

      //get the data from session storage and change state
      const combinedArray = JSON.parse(thisDate).concat(
        JSON.parse(dateOfTomorrow),
      );

      setData(combinedArray);
    }
  }

  async function getData() {
    try {
      const response = await fetch(`${hostNameServer}/getdata`);
      if (response.ok) {
        const jsonData = await response.json();
        console.log(jsonData);

        const dataWithTooltip = jsonData.map((item) => {
          const updatedPrice = item.price + 0.15;
          const tooltipValue = updatedPrice.toFixed(2);
          const formattedTooltip = `€ ${tooltipValue.replace(".", ",")}`;

          return {
            ...item,
            price: updatedPrice,
            tooltip: formattedTooltip,
          };
        });

        setData(dataWithTooltip);

        //save to localStorage

        //date of today
        const currentDate = new Date();

        //date of tomorrow
        const tomorrowDate = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate() + 1);

        //save today and or tomorrow
        if (dataWithTooltip.length <= 24) {
          //only today is available

          //get current date with time 00:00:00
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          //check if already stored
          const thisDate = sessionStorage.getItem(currentDate);

          if (!thisDate) {
            sessionStorage.setItem(
              currentDate,
              JSON.stringify(dataWithTooltip),
            );
          }
        } else {
          console.log("morgen is beschikbaar");
          //flow om morgen op te slaan

          //split the array in 2
          const todayArray = dataWithTooltip.slice(0, 24);
          const tomorrowArray = dataWithTooltip.slice(24, 48);

          //flow to save the array of today
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          //check if already stored
          const thisDate = sessionStorage.getItem(currentDate);

          if (!thisDate) {
            sessionStorage.setItem(currentDate, JSON.stringify(todayArray));
          }

          //flow to save the tomorrow array
          const tomorrowDate = new Date();
          tomorrowDate.setDate(tomorrowDate.getDate() + 1);
          tomorrowDate.setHours(0, 0, 0, 0);

          //check if already stored
          const dateOfTomorrow = sessionStorage.getItem(tomorrowDate);

          if (!dateOfTomorrow) {
            sessionStorage.setItem(tomorrowDate, JSON.stringify(tomorrowArray));
          }
        }

        console.log(dataWithTooltip);
      } else {
        console.error(
          "Fout bij het ophalen van gegevens:",
          response.statusText,
        );
      }
    } catch (error) {
      console.error("Fout bij het ophalen van gegevens:", error);
    }
  }

  useEffect(() => {
    getChargeData();
  }, []);

  useEffect(() => {
    async function getPowerData() {
      try {
        const response = await fetch(`${hostNameServer}/getcurrentpower`);
        if (response.ok) {
          const jsonData = await response.json();
          console.log(jsonData);
        } else {
          console.error(
            "Fout bij het ophalen van gegevens:",
            response.statusText,
          );
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);
      }
    }

    getPowerData();
  }, []);

  const [chargeHour, setChargeHour] = useState();
  function chargehours(prop) {
    console.log(prop);
    setChargeHour(prop);
    // console.log(chargeHour)
  }

  //Handle Charge click
  async function handleClick() {
    try {
      const response = await fetch(`${hostNameServer}/scheduledcharging`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(chargeHour),
      });
      console.log(chargeHour);

      if (response.ok) {
        // const responseData = await response.json();
        // console.log('Response data:', responseData);
        setDisplayButon(false);
      } else {
        console.error(
          "Fout bij het versturen van gegevens:",
          response.statusText,
        );
      }
    } catch (error) {
      console.error("Fout bij het versturen van gegevens:", error);
    }
  }

  //function to get the next charge moment
  function notification(time) {
    // console.log(time)
    setChargeNotification(time);
  }

  return (
    <div className="ChargePlanning">
      {/* {power && <div className='currentPower'>Productie: {power} wH</div>} */}
      <div className="container">
        {/* <div className='row topRow'>

          <div className='col-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-chevron-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            </svg>

          </div>

        </div> */}

        <div className="row">
          <div className="col-12">
            {data.length > 0 && (
              <BarChartComponent
                data={data}
                chargehours={chargehours}
                chargeHours={chargeHours}
                showButton={showButton}
                notification={notification}
              />
            )}
            {data.length > 0 && (
              <>
                {settings?.teslaTurnedOn || settings?.hyundaiTurnedOn ? (
                  <div className="justifyContentCenter mt-3">
                    <span style={{ color: "#25c781" }}>
                      {settings?.teslaTurnedOn
                        ? "Tesla smart charge staat momenteel aan"
                        : "Hyundai smart charge staat momenteel aan"}
                    </span>
                  </div>
                ) : (
                  <div className="charBar">
                    {displayButton && (
                      <button
                        type="button"
                        className="btn btn-success custom-btn-width"
                        onClick={handleClick}
                      >
                        Charge{" "}
                      </button>
                    )}
                    {!displayButton && (
                      <button
                        type="button"
                        className="btn btn-outline-light custom-btn-width"
                      >
                        Charge
                      </button>
                    )}
                    {!displayButton && (
                      <div className="notificationText">
                        {chargeNotification}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            <div className="footerBox"></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargePlanning;
