import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Link } from "react-router-dom";
import { React } from "react";

const NavItem = ({ to, icon, title, children, onClick }) => {
  const renderLinkItem = () => (
    <Link to={to} className="navItemBar">
      <div className="row navItemRow">
        <div className="col-2 navColumnItem justifyContentCenter">
          {icon && icon}
        </div>
        <div className="col-8 navColumnItem">
          <div>
            <h4 style={{ fontSize: "1.1rem" }}>{title}</h4>
            {children}
          </div>
        </div>

        <div className="col-2 alignItemsCenter justifyContentCenter">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 320 512"
          >
            <path
              d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
      </div>
    </Link>
  );

  const renderClickableItem = () => (
    <div onClick={onClick} className="navItemBar">
      <div className="row navItemRow">
        <div className="col-2 navColumnItem justifyContentCenter">
          {icon && icon}
        </div>
        <div className="col-8 navColumnItem">
          <div className="col-8 navColumnItem">
            <div>
              <h4 style={{ fontSize: "1.1rem" }}>{title}</h4>
              {children}
            </div>
          </div>
        </div>
        <div className="col-2 alignItemsCenter justifyContentCenter">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 320 512"
          >
            <path
              d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
      </div>
    </div>
  );

  return to ? renderLinkItem() : renderClickableItem();
};

export default NavItem;
