import { React, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ChargePlanning from "./pages/ChargePlanning";
import Login from "./pages/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useAuthContext } from "./Hooks/useAuthContext";
import Settings from "./pages/Settings";
import Tesla from "./pages/Tesla";
import useGetAppSettings from "./Hooks/useGetAppSettings";
import Statistics from "./pages/Statistics";
import Logging from "./pages/Logging";

function App() {
  const { user, settings } = useAuthContext();
  const { getAppSettings } = useGetAppSettings();

  const prevSettings = useRef(settings);

  useEffect(() => {
    if (user && !settings) {
      getAppSettings();
    }

    if (JSON.stringify(settings) !== JSON.stringify(prevSettings.current)) {
      const timeoutId = setTimeout(() => {
        getAppSettings();
        prevSettings.current = settings;
      }, 2000);

      return () => clearTimeout(timeoutId);
    }

    // eslint-disable-next-line
  }, [settings, user]);

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#25c781",
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/chargeplanning"
              element={user ? <ChargePlanning /> : <Navigate to="/login" />}
            />
            <Route
              path="/instellingen"
              element={user ? <Settings /> : <Navigate to="/login" />}
            />
            <Route
              path="/tesla"
              element={user ? <Tesla /> : <Navigate to="/login" />}
            />
            <Route
              path="/statistieken"
              element={user ? <Statistics /> : <Navigate to="/login" />}
            />
            <Route
              path="/logging"
              element={user ? <Logging /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={user ? <Navigate to="/" /> : <Login />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
