import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useAuthContext } from "../Hooks/useAuthContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChartComponent = (props) => {
  const [today, setToday] = useState(true);
  const [tomorrowAvailable, setTomorrow] = useState(props.data.length > 24);
  const [selectedBars, setSelectedBars] = useState([]);
  const [selectedBarsTomorrow, setSelectedBarsTomorrow] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalCosts, setCosts] = useState();
  const [km, setKm] = useState();
  const [pricePerKm, setPricePerKm] = useState(0);
  const [kwh, setKwh] = useState();
  const [savings, setSavings] = useState();
  const [selected, setSelected] = useState();
  const [displayCosts, setDisplayCosts] = useState();
  const [savingsInEuro, setSavingsInEuro] = useState();
  const { settings } = useAuthContext();

  const { displayCharge, chargehours, chargeHours, showButton, notification } =
    props;

  //maak array met true en false
  useEffect(() => {
    // console.log(selectedBars)
    //slice array
    const eersteHelft = props.data.slice(0, 24);
    const tweedeHelft = props.data.slice(24);

    //add true en false of de bars geselecteerd zijn
    eersteHelft.forEach((item, index) => {
      item.selected = selectedBars.includes(index);
    });

    tweedeHelft.forEach((item, index) => {
      item.selected = selectedBarsTomorrow.includes(index);
    });

    const totalArray = eersteHelft.concat(tweedeHelft);
    setAllData(totalArray);
  }, [selectedBars, selectedBarsTomorrow]);

  //get current gasoline price
  useEffect(() => {
    // const url = "https://opendata.cbs.nl/ODataApi/OData/80416ned/TypedDataSet";
    // fetch(url)
    //     .then(response => {
    //         if (response.ok) {
    //             return response.json();
    //         } else {
    //             throw new Error('Fout bij het ophalen van de gegevens.');
    //         }
    //     })
    //     .then(data => {
    //         const sortedData = data.value.sort((a, b) => b.Perioden.localeCompare(a.Perioden));
    //         const lastBenzinePrice = sortedData[0].BenzineEuro95_1;
    //         console.log(`De laatste BenzineEuro95_1 waarde is: ${lastBenzinePrice}`);
    //     })
    //     .catch(error => {
    //         console.error(error.message);
    //     });
  }, []);

  //calculate data for kwh, km, costs, etc
  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    //Calculate the number of hours being charged
    if (allData) {
      const selectedItemCount = allData.reduce((count, item, index) => {
        if (item.selected && index >= currentHour) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);
      setSelected(selectedItemCount);
    }
  }, [allData]);

  // set Kwh, Km, costs
  useEffect(() => {
    setKm(selected * 51);
    setKwh((selected * 7, 4).toFixed(1));

    if (isNaN((totalCosts / km).toFixed(3))) {
      setPricePerKm(0);
    } else {
      setPricePerKm((totalCosts / km).toFixed(3));
    }

    // console.log(km, kwh, pricePerKm)

    //calc saving
    const totFuelPrice = (2.07 / 14) * km;
    const totalSaving = (totFuelPrice - totalCosts).toFixed(2);
    setSavings(totalSaving);
    if (savings) {
      setSavingsInEuro(savings.replace(/\./g, ","));
    }
    // console.log(savings)
  }, [selected, totalCosts, km, savings]);

  useEffect(() => {
    const total = allData
      .filter((item) => item.selected)
      .reduce((accumulator, item) => accumulator + item.price * 7.4, 0);

    setCosts(total.toFixed(2));
    if (totalCosts) {
      // console.log(totalCosts)
      setDisplayCosts(totalCosts.replace(/\./g, ","));
    }
    // console.log(total)
  }, [selected, totalCosts]);

  //genereer notificatie wanneer het laden begint
  useEffect(() => {
    // console.log(selectedBars.length)
    if (selectedBars.length >= 1) {
      // console.log(selectedBars)

      const now = new Date();
      const currentHour = now.getHours();

      const nextMomentToday = selectedBars.reduce((min, num) => {
        if (num >= currentHour && (min === undefined || num < min)) {
          return num;
        }
        return min;
      }, undefined);

      notification(`Laden begint vandaag om ${nextMomentToday}:00u`);
    } else if (selectedBarsTomorrow.length >= 1) {
      const nextMomentTomorrow = selectedBarsTomorrow.reduce((min, num) => {
        if (min === undefined || num < min) {
          return num;
        }
        return min;
      }, undefined);

      notification(`Laden begint morgen om ${nextMomentTomorrow}:00u`);
    } else {
      notification("");
    }
  }, [selectedBars, selectedBarsTomorrow]);

  //determine selected bars
  useEffect(() => {
    // console.log(chargeHours)
    if (chargeHours) {
      //devide the dates over today and tomorrow
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      //datum van morgen
      const tomorrowDate = new Date(now);
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      tomorrowDate.setHours(0, 0, 0, 0);

      // Formateer de datum als "YYYY-MM-DD"
      const formattedDate = `${now.getFullYear()}-${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;

      // Maak een kopie van de huidige datum en voeg één dag toe voor de datum van morgen
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const formattedDateTomorrow = `${tomorrow.getFullYear()}-${(
        tomorrow.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${tomorrow.getDate().toString().padStart(2, "0")}`;

      const gefilterdeDatumToday = chargeHours.filter((datum) => {
        return datum.includes(formattedDate);
      });

      const gefilterdeDatumTomorrow = chargeHours.filter((datum) => {
        return datum.includes(formattedDateTomorrow);
      });

      // console.log(gefilterdeDatumToday);
      // console.log(gefilterdeDatumTomorrow)

      const actualDate = new Date();
      const currentHour = actualDate.getUTCHours() + 2;

      const todayTimes = gefilterdeDatumToday.map((datum) => {
        const uur = new Date(datum).getUTCHours();
        return uur;
      });

      //verberg uren in het verleden
      const actualHours = todayTimes.filter((uur) => uur >= currentHour);

      const tomorrowTimes = gefilterdeDatumTomorrow.map((datum) => {
        const uur = new Date(datum).getUTCHours();
        return uur;
      });
      console.log(settings);
      settings.hyundaiTurnedOn
        ? setSelectedBars(todayTimes)
        : setSelectedBars(actualHours);
      setSelectedBarsTomorrow(tomorrowTimes);
    }
  }, [chargeHours]);

  useEffect(() => {
    console.log(selectedBars);
    console.log(chargeHours);
  }, [selectedBars, chargeHours]);

  //Maak array met charge tijden
  useEffect(() => {
    const todayArray = [];
    selectedBars.map((time) => {
      const getDate = convertToTime(time, false);

      todayArray.push(getDate);
    });

    selectedBarsTomorrow.map((time) => {
      const getDate = convertToTime(time, true);
      todayArray.push(getDate);
    });

    //send chargehours to app.js
    chargehours(todayArray);
  }, [selectedBars, selectedBarsTomorrow]);

  //Functie om tijd op te slaan
  function convertToTime(hour, isTomorrow) {
    const now = new Date();
    const targetDate = new Date(now);

    if (isTomorrow) {
      targetDate.setDate(targetDate.getDate() + 1);
    }

    const year = targetDate.getFullYear();
    const month = targetDate.getMonth() + 1; // Maanden zijn zero-based, dus we voegen 1 toe
    const day = targetDate.getDate();

    const isoTimeString = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}T${hour.toString().padStart(2, "0")}:00:00.000Z`;

    return new Date(isoTimeString);
  }

  //current hour
  const currentHour = new Date().getHours();

  function handleBarClick(event, elements) {
    if (!elements.length) return; // Geen elementen geselecteerd

    if (today) {
      const clickedIndex = elements[0].index;

      if (today && clickedIndex < currentHour) {
        return;
      }

      // Controleer of de geselecteerde balk al in de lijst staat
      if (selectedBars.includes(clickedIndex)) {
        setSelectedBars(selectedBars.filter((index) => index !== clickedIndex));
      } else {
        setSelectedBars([...selectedBars, clickedIndex]);
      }
      showButton(true);
    } else {
      const clickedIndex = elements[0].index;

      // Controleer of de geselecteerde balk al in de lijst staat
      if (selectedBarsTomorrow.includes(clickedIndex)) {
        setSelectedBarsTomorrow(
          selectedBarsTomorrow.filter((index) => index !== clickedIndex),
        );
      } else {
        setSelectedBarsTomorrow([...selectedBarsTomorrow, clickedIndex]);
      }
      showButton(true);
    }
  }

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 10,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Energietarieven",
      },
    },
    scales: {
      y: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          stepSize: 2,
          color: "white",
        },
        barThickness: 20,
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: "white",
          callback: function (value, index, values) {
            return "€" + value.toFixed(2); // Voeg '€' toe en formateer naar 2 decimalen
          },
        },
      },
    },
    maintainAspectRatio: false,
    onClick: handleBarClick,
    plugins: {
      tooltip: {
        enabled: false, // Schakel tooltips uit
      },
      legend: {
        display: false, // Schakel de legenda uit
      },
    },
  };

  const labels = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];

  //Data op de y-as
  const selectedData = today
    ? props.data.slice(0, 24)
    : props.data.slice(24, 48);
  const prices = selectedData.map((item) => item.price);

  //calc highest price
  const highestPrice = props.data.reduce((maxPrice, currentObj) => {
    if (currentObj.price > maxPrice) {
      return currentObj.price;
    } else {
      return maxPrice;
    }
  }, -Infinity);

  //calc lowest price
  const lowestPrice = props.data.reduce((minPrice, currentObj) => {
    if (currentObj.price < minPrice) {
      return currentObj.price;
    } else {
      return minPrice;
    }
  }, Infinity);

  //calc difference
  const priceDifference = highestPrice - lowestPrice;

  //calc top price
  const topPrice = Number(
    (highestPrice - (priceDifference / 100) * 33).toFixed(2),
  );

  //calc low price
  const lowPrice = Number(
    (lowestPrice + (priceDifference / 100) * 33).toFixed(2),
  );

  useEffect(() => {
    console.log(selectedBars);
  }, [selectedBars]);

  const backgroundColors = prices.map((price, index) => {
    if (today) {
      if (selectedBars.includes(index)) {
        return "rgba(0, 170, 101)"; // Kleur voor geselecteerde balken
      } else {
        if (settings.hyundaiTurnedOn ? 0 : currentHour > index) {
          return "rgba(255, 255, 255, 0.1)";
        } else {
          return "rgba(255, 255, 255)";
        }
      }
    }
    if (!today) {
      if (selectedBarsTomorrow.includes(index)) {
        return "rgba(0, 170, 101)"; // Kleur voor geselecteerde balken
      } else {
        return "rgba(255, 255, 255)";
      }
    }
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Uurtarieven",
        data: prices, // Zelfbedachte cijfers tussen 0 en 100
        backgroundColor: backgroundColors,
        barThickness: 15,
      },
    ],
  };

  //date title
  const currentDate = new Date().toLocaleDateString("nl-NL", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  //handleArrowClick
  function handleArrowRightClick() {
    setToday(false);
  }

  function handleArrowLeftClick() {
    setToday(true);
  }

  //change date to tomorrow
  function getFormattedTomorrowDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formattedTomorrow = tomorrow.toLocaleDateString("nl-NL", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    return formattedTomorrow;
  }

  const tomorrowDate = getFormattedTomorrowDate();

  return (
    <div>
      <div className="headerBox">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <Link to="/">
                <svg
                  className="backIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 320 512"
                >
                  <path
                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                    fill="#FFFFFF"
                  />
                </svg>
              </Link>
            </div>
            <div className="col-1 " onClick={handleArrowLeftClick}>
              {!today && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-arrow-left-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                    fill="#FFFFFF"
                  />
                </svg>
              )}
            </div>
            <div className=" col-6 justifyContentCenter dateBox">
              {today && currentDate}
              {!today && tomorrowDate}
            </div>
            <div className=" col-1" onClick={handleArrowRightClick}>
              {tomorrowAvailable && today && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-arrow-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                    fill="#FFFFFF"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row statisticsRow">
        <div className="chargeStatistics">
          <span className="statisticNumber">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="statisticIcon"
              height="1em"
              viewBox="0 0 576 512"
            >
              <path d="M565.6 36.2C572.1 40.7 576 48.1 576 56V392c0 10-6.2 18.9-15.5 22.4l-168 64c-5.2 2-10.9 2.1-16.1 .3L192.5 417.5l-160 61c-7.4 2.8-15.7 1.8-22.2-2.7S0 463.9 0 456V120c0-10 6.1-18.9 15.5-22.4l168-64c5.2-2 10.9-2.1 16.1-.3L383.5 94.5l160-61c7.4-2.8 15.7-1.8 22.2 2.7zM48 136.5V421.2l120-45.7V90.8L48 136.5zM360 422.7V137.3l-144-48V374.7l144 48zm48-1.5l120-45.7V90.8L408 136.5V421.2z" />
            </svg>
            {km} km
          </span>
        </div>
        {/* <div className='chargeStatistics'>
                <span className='statisticNumber'>
                <svg xmlns="http://www.w3.org/2000/svg" className="statisticIcon" height="1em" viewBox="0 0 448 512"><path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"/></svg>
                    {kwh} kWh</span>
                </div> */}
        <div className="chargeStatistics">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
            className="statisticIcon"
          >
            <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
          </svg>
          <span className="statisticNumber">€{displayCosts}</span>
        </div>
        <div className="chargeStatistics">
          <span className="statisticNumber">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="statisticIcon"
              height="1em"
              viewBox="0 0 640 512"
            >
              <path d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
            €{pricePerKm}/km
          </span>
        </div>
        <div className="chargeStatistics">
          <span className="statisticNumber">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="statisticIcon"
              height="1em"
              viewBox="0 0 576 512"
            >
              <path d="M400 96l0 .7c-5.3-.4-10.6-.7-16-.7H256c-16.5 0-32.5 2.1-47.8 6c-.1-2-.2-4-.2-6c0-53 43-96 96-96s96 43 96 96zm-16 32c3.5 0 7 .1 10.4 .3c4.2 .3 8.4 .7 12.6 1.3C424.6 109.1 450.8 96 480 96h11.5c10.4 0 18 9.8 15.5 19.9l-13.8 55.2c15.8 14.8 28.7 32.8 37.5 52.9H544c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H512c-9.1 12.1-19.9 22.9-32 32v64c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H256v32c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V416c-34.9-26.2-58.7-66.3-63.2-112H68c-37.6 0-68-30.4-68-68s30.4-68 68-68h4c13.3 0 24 10.7 24 24s-10.7 24-24 24H68c-11 0-20 9-20 20s9 20 20 20H99.2c12.1-59.8 57.7-107.5 116.3-122.8c12.9-3.4 26.5-5.2 40.5-5.2H384zm64 136a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
            </svg>
            €{savingsInEuro}
          </span>
        </div>
      </div>
      <div>
        <Bar options={options} data={data} width={100} height={700} />
      </div>
    </div>
  );
};

export default BarChartComponent;
