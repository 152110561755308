import HeaderBox from "../components/UI/HeaderBox";
import CarSetting from "../components/UI/CarSetting";
import { React, useEffect } from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
import {
  hourlyMoments,
  hyundaiEndChargeMoment,
  hyundaiStartChargeMoment,
} from "../Utils/Items";
import { distanceValues } from "../Utils/Items";
import { Grid } from "@mui/material";
import { hyundaihourlyMoments } from "../Utils/Items";

const Settings = (props) => {
  const { settings } = useAuthContext();

  return (
    <div className="container">
      <div className="row">
        <div className="mb-1">
          <HeaderBox />
        </div>
        <div className="mt-3">
          <CarSetting
            title="Tesla"
            type="checkbox"
            settingKey="teslaTurnedOn"
          />
          {settings?.teslaTurnedOn && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CarSetting
                  title="Tijdstip gereed"
                  type="dropdown"
                  settingKey="teslaTimeReady"
                  dropdownItems={hourlyMoments}
                />
              </Grid>
              <Grid item xs={6}>
                <CarSetting
                  title="Gewenst aantal km"
                  type="dropdown"
                  settingKey="teslaDesiredDistance"
                  dropdownItems={distanceValues}
                />
              </Grid>
            </Grid>
          )}
        </div>
        <div className="mt-3">
          <CarSetting
            title="Hyundai"
            type="checkbox"
            settingKey="hyundaiTurnedOn"
          />
          {settings?.hyundaiTurnedOn && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CarSetting
                  title="Aantal uur laden"
                  type="dropdown"
                  settingKey="hyundaiDesiredQuantityHours"
                  dropdownItems={hyundaihourlyMoments(settings)}
                />
              </Grid>

              <Grid item xs={6}>
                <CarSetting
                  title="Start tijdstip"
                  type="dropdown"
                  settingKey="hyundaiStartTime"
                  dropdownItems={hyundaiStartChargeMoment(settings)}
                />
              </Grid>
              <Grid item xs={6}>
                <CarSetting
                  title="Eind tijdstip"
                  type="dropdown"
                  settingKey="hyundaiEndTime"
                  dropdownItems={hyundaiEndChargeMoment(settings)}
                />
              </Grid>
            </Grid>
          )}
        </div>
        <div className="mt-3">
          <CarSetting
            title="Handmatig"
            type="checkbox"
            settingKey="manualTurnedOn"
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
