import hostNameServer from "../configHost";
import { useAuthContext } from "./useAuthContext";

const useChargerStatus = () => {
  const { dispatch } = useAuthContext();

  const getChargerStatus = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(`${hostNameServer}/chargerstatus`);

        const jsonData = await response.json();

        if (response.ok) {
          dispatch({ type: "FETCH_CHARGER_STATE", payload: jsonData });
          resolve(jsonData);
        } else {
          console.error(
            "Fout bij het ophalen van gegevens:",
            response.statusText,
          );
          reject("error");
        }
      } catch (error) {
        console.error("Fout bij het ophalen van gegevens:", error);
      }
    });
  };

  return { getChargerStatus };
};

export default useChargerStatus;
