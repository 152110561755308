import HeaderBox from "../components/UI/HeaderBox";
import { React, useEffect } from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
import StatisticRow from "../components/UI/StatisticRow";
import useChargerStatus from "../Hooks/useChargerStatus";
import useGetAppSettings from "../Hooks/useGetAppSettings";

const Statistics = () => {
  const { chargerState } = useAuthContext();
  const { getChargerStatus } = useChargerStatus();
  const { settings } = useAuthContext();
  const { getAppSettings } = useGetAppSettings();

  useEffect(() => {
    getChargerStatus();
    getAppSettings();
  }, []);

  function chargeMode(settings) {
    if (settings.teslaTurnedOn) {
      return "Tesla Auto Charge";
    } else if (settings.hyundaiTurnedOn) {
      return "Hyundai Auto Charge";
    } else {
      return "Handmatig";
    }
  }

  function statusCharger(state) {
    if (state === "Standby") {
      return "Standby";
    } else if (state === "VehicleDetected") {
      return "Voertuig gedetecteerd";
    } else if (state === "Charging") {
      return "Aan het laden";
    } else {
      return state;
    }
  }

  function chargerNotifications() {
    const currentYear = new Date().getFullYear();

    if (settings?.chargerStatus.startsWith(currentYear.toString())) {
      return "Laden succesvol ingepland";
    } else if (
      settings.chargerStatus ===
      "current distance is greater then desired distance"
    ) {
      return "Er is voldoende bereik";
    } else if (
      settings.chargerStatus === "margin to little to charge (15km margin"
    ) {
      return "Bereik valt binnen de marge (15km)";
    } else if (settings.chargerStatus === "No chargemoments to plan") {
      return "Geen laadmomenten om te plannen";
    }
    {
      return settings.chargerStatus;
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="mb-3">
          <HeaderBox />
        </div>
        <div>
          <StatisticRow text="Charge modus" value={chargeMode(settings)} />
          <StatisticRow
            text="Voertuig verbonden"
            value={
              chargerState.chargerStatus.indicators.isVehicleConnected === true
                ? "Ja"
                : "Nee"
            }
          />
          <StatisticRow
            text="Internet status"
            value={chargerState.cloudConnectionState}
          />

          <StatisticRow
            text="Status charger"
            value={statusCharger(
              chargerState.chargerStatus.indicators.chargingState,
            )}
          />
          <StatisticRow
            text="Huidig laadvermogen"
            value={chargerState.chargeSessionStatus.actualChargingPower + " W"}
          />
          {chargerState.chargerStatus.indicators.errors.lenght > 0 && (
            <StatisticRow
              text="Errors"
              value={chargerState.chargerStatus.indicators.errors}
            />
          )}
          <StatisticRow
            text="Charge sessie gepauzeerd"
            value={
              chargerState.chargerStatus.indicators.isChargingPaused === true
                ? "Ja"
                : "Nee"
            }
          />
          <StatisticRow
            text="Actieve charge sessie"
            value={
              chargerState.chargerStatus.indicators.isChargeSessionActive ===
              true
                ? "Ja"
                : "Nee"
            }
          />
          <StatisticRow
            text="Kan een laadsessie gestart worden?"
            value={
              chargerState.chargerStatus.isChargeStartAllowed === true
                ? "Ja"
                : "Nee"
            }
          />
          <StatisticRow
            text="Kan een laadsessie gestopt worden?"
            value={
              chargerState.chargerStatus.isChargeStopAllowed === true
                ? "Ja"
                : "Nee"
            }
          />
          <StatisticRow text="Meldingen" value={chargerNotifications()} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
