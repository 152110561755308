export const hourlyMoments = Array.from({ length: 24 }, (_, index) => ({
  value: index,
  label: `${index.toString().padStart(2, "0")}:00u`,
}));

export const distanceValues = Array.from({ length: 10 }, (_, index) => ({
  value: 100 + index * 25,
  label: `${100 + index * 25} km`,
}));

// export const hyundaihourlyMoments = Array.from({ length: 8 }, (_, index) => ({
//   value: index + 1,
//   label: `${(index + 1).toString()} uur`,
// }));

export const hyundaihourlyMoments = (settings) => {
  const lengthArray = Math.min(
    settings.hyundaiEndTime - settings.hyundaiStartTime,
    8,
  );

  return Array.from({ length: lengthArray }, (_, index) => ({
    value: index + 1,
    label: `${(index + 1).toString()} uur`,
  }));
};
export const hyundaiStartChargeMoment = (settings) => {
  const startHour = 6;
  const endHour = Math.max(
    settings.hyundaiEndTime - settings.hyundaiDesiredQuantityHours + 1,
    startHour,
  );

  return Array.from({ length: endHour - startHour }, (_, index) => ({
    value: startHour + index,
    label: `${(startHour + index).toString().padStart(2, "0")}:00u`,
  }));
};

export const hyundaiEndChargeMoment = (settings) => {
  const startHour =
    settings.hyundaiStartTime + settings.hyundaiDesiredQuantityHours;
  const endHour = Math.min(24);

  return Array.from({ length: endHour - startHour }, (_, index) => ({
    value: startHour + index,
    label: `${(startHour + index).toString().padStart(2, "0")}:00u`,
  }));
};
