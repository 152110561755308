import hostNameServer from "../configHost";
import { React, useEffect, useState } from "react";
import HeaderBox from "../components/UI/HeaderBox";
import LogItem from "../components/UI/LogItem";

const Logging = () => {
  const [logging, setLogging] = useState();

  const getLogging = async () => {
    try {
      const response = await fetch(`${hostNameServer}/logging`);

      const jsonData = await response.json();

      if (response.ok) {
        setLogging(jsonData);
        console.log(jsonData);
      } else {
        console.error(
          "Fout bij het ophalen van gegevens:",
          response.statusText,
        );
      }
    } catch (error) {
      console.error("Fout bij het ophalen van gegevens:", error);
    }
  };

  useEffect(() => {
    getLogging();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="mb-3">
          <HeaderBox />
        </div>
        {logging &&
          logging.map((log) => {
            return <LogItem key={log._id} log={log} />;
          })}
      </div>
    </div>
  );
};

export default Logging;
